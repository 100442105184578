<script lang="ts" setup>
import escape from 'escape-html';

const props = withDefaults(
  defineProps<{
    text?: string;
    isBreakLine?: boolean;
    urlDecorator?: (u: URL) => URL;
  }>(),
  {
    text: '',
    isBreakLine: true,
    urlDecorator: (u: URL) => u,
  }
);

const processedText = computed((): string =>
  parseUserInputText(props.text)
    .map((node) => {
      if (node.type !== 'url') return escape(node.value);
      try {
        const url = new URL(node.value);
        return linkifyUrl(props.urlDecorator(url).toString(), node.value);
      } catch (_) {
        return linkifyUrl(node.value);
      }
    })
    .join('')
);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <p
    :class="[$style.text, { [$style['text--break']]: isBreakLine }]"
    v-html="processedText"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<style lang="scss" module>
.text {
  word-break: break-all;
  white-space: normal;

  &--break {
    white-space: pre-wrap;
  }
}

.text :deep(a) {
  color: $color-text-and-object-accent-primary;
}
</style>
