import createHtmlElement from 'create-html-element';

interface linkifyOptions {
  readonly attributes?: {
    [attributeName: string]: string | number | boolean | readonly string[];
  };
  readonly value?: string | ((url: string) => string);
  readonly text?: string;
}

const linkify = (href: string, options: linkifyOptions) =>
  createHtmlElement({
    name: 'a',
    attributes: {
      href: href || '',
      ...options.attributes,
    },
    text: options.text ?? href,
  });

export const linkifyUrl = (url: string, text?: string): string =>
  linkify(url, {
    attributes: {
      target: '_blank',
      rel: 'norefferer noopener',
    },
    text: text,
  });
