import urlRegex from 'url-regex';

export type PlainTextNode = { type: 'plain'; value: string };
export type UrlTextNode = { type: 'url'; value: string };
export type ParsedTextNode = PlainTextNode | UrlTextNode;

const firstNode = (
  text: string
): { node: ParsedTextNode; remainingText: string } => {
  // 再帰のたびににregex作るの重い気もするけど呼び出し回数たかが知れてるし g フ
  // ラグがついてるせいで exec するたびに状態と結果が変わってややこしいけど
  // lastIndexに0ぶち込んだりgフラグ外したregexオブジェクト作るのもコード的に遠
  // 回りで分かりづらくなるのでおとなしく毎回インスタンス作ってる
  const re = urlRegex();
  const match = re.exec(text);

  if (match == null) {
    return {
      node: {
        type: 'plain',
        value: text,
      },
      remainingText: '',
    };
  }
  if (match.index == 0) {
    const [url] = match;
    return {
      node: {
        type: 'url',
        value: url,
      },
      remainingText: text.slice(url.length),
    };
  }
  return {
    node: {
      type: 'plain',
      value: text.slice(0, match.index),
    },
    remainingText: text.slice(match.index),
  };
};

export const parseUserInputText = (text: string): ParsedTextNode[] => {
  const iter = (acc: ParsedTextNode[], text: string): ParsedTextNode[] => {
    if (text === '') {
      return acc;
    }
    const { node, remainingText } = firstNode(text);
    return iter([...acc, node], remainingText);
  };
  return iter([], text);
};
